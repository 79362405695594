import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import PageBaseModule from '@/admin/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';

import store from '@/admin/store';
import { getTransactionOperationNames, getTransactionsList } from '@/admin/api/transaction';
import { prepareTableTransactions } from '@/lib/Finance';
import { getOrdersStatuses } from '@/admin/api/orders';
import { generateFilter } from '@/lib/utils/Utils';

const MODULE_NAME = 'financeTable';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FinanceOrdersTableModule extends PageBaseModule {
    tableType = '';
    operationNames: { id: string; name: string }[] = [];
    statuses: { id: string; name: string }[] = [];

    constructor(module: FinanceOrdersTableModule) {
        super(module);

        this.isLoading = false;

        const page = new PageEntity();
        page.setDefaultSortField('name');
        page.setSortName('FinanceTransactionsSort');
        this.pageSettings = page.values;
    }

    @Mutation
    SET_TABLE_TYPE(type: string) {
        this.tableType = type;
    }

    @Mutation
    SET_OPERATION_NAMES(list: { id: string; name: string }[]) {
        this.operationNames = list;
    }

    @Mutation
    SET_STATUSES(list: { id: string; name: string }[]) {
        this.statuses = list;
    }

    @Action({ rawError: true })
    async updateSortValue(params: { field: string; sort: string }) {
        const sortProcessed = await this.sortProcessed(params);

        this.context.commit('SET_SORT', sortProcessed);
    }

    @Action({ rawError: true })
    updateTableType(type: string) {
        this.SET_TABLE_TYPE(type);
    }

    @Action({ rawError: true })
    async initOperationNames() {
        this.SET_OPERATION_NAMES(await getTransactionOperationNames());
    }

    @Action({ rawError: true })
    async initOrderStatuses() {
        this.SET_STATUSES(await getOrdersStatuses());
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();
        const promises = [this.initOperationNames(), this.initOrderStatuses()];
        await Promise.all(promises);
    }

    @Action({ rawError: true })
    updateItems(number: string | number) {
        this.pageSettings.pageAmountItems = number.toString();
    }

    @Action({ rawError: true })
    async getTable(params: { customerId?: number; sellerName?: string; catalogName?: string; subjectType?: string }) {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();

        const filterItems = [];

        if (params.customerId) {
            filterItems.push({ id: 'customer', value: String(params.customerId) });
        }

        if (params.sellerName) {
            filterItems.push({ id: 'seller', value: String(params.sellerName) });
        }

        if (params.catalogName) {
            filterItems.push({ id: 'auction', value: String(params.catalogName) });
        }

        if (params.subjectType) {
            filterItems.push({ id: 'subjectType', value: String(params.subjectType) });
        }

        const result = await getTransactionsList(
            1,
            sort,
            generateFilter(filterItems),
            +this.pageSettings.pageAmountItems,
        );

        await this.setInfo(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setInfo(table: TableApiInterface) {
        const tableProcessed = await prepareTableTransactions(table, this.operationNames, this.statuses);

        this.SET_TABLE(tableProcessed);
    }

    @Action({ rawError: true })
    resetTable() {
        this.RESET_TABLE();
    }
}
export default getModule(FinanceOrdersTableModule);
