import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';

import store from '@/admin/store';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './sellerModel';
import { generateFilter, generateSort, getApiErrorMessage } from '@/lib/utils/Utils';
import { SelectItem } from '@/lib/formFactory/select.interface';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { cancelTransaction, createTransaction } from '@/admin/api/transaction';
import { getAuctionHouseList } from '@/admin/api/auctionHouse';
import { getGalleryList } from '@/admin/api/gallery';
import { getPainterList } from '@/admin/api/painter';
import { processListForSelect } from '@/lib/utils/form';
import { getCatalogAuctionList } from '@/admin/api/catalogAuction';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { formatRemoveSpaces } from '@/lib/utils/Utils';

const MODULE_NAME = 'financeSeller';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FinanceSellerEntity extends EntityBaseModule {
    model: any;
    selectDefault = { id: 0, value: '' };
    requestSort = '';
    isSendBlocked = false;

    constructor(module: FinanceSellerEntity) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
        this.requestSort = generateSort({ id: 'name', value: 'ASC' });
    }

    @Mutation
    SET_IS_SEND_BLOCKED(bool: boolean) {
        this.isSendBlocked = bool;
    }

    @Mutation
    SET_OPERATION_TYPE(value: string) {
        this.model.operationType.value = value;
    }

    @Mutation
    SET_SUMM(summ: string) {
        this.model.summ.value = summ;
    }

    @Mutation
    SET_COST_DELIVERY(cost: number | string) {
        this.model.costDelivery = cost;
    }

    @Mutation
    SET_SELLER_TYPE(type: string) {
        this.model.sellerType = type;
    }

    @Mutation
    SET_SELLER_TYPE_FORM(selected: SelectItem) {
        this.model.sellerTypeForm.current = selected;
    }

    @Mutation
    SET_CATALOG_IS_REQUIRED(bool: boolean) {
        this.model.catalog.required = bool;
    }

    @Action({ rawError: true })
    updateOperationType(type: string) {
        this.SET_OPERATION_TYPE(type);
    }

    @Action({ rawError: true })
    updateSumm(summ: string) {
        this.RESET_ERROR_BY_FIELD('summ');
        this.SET_SUMM(formatRemoveSpaces(summ));
    }

    @Action({ rawError: true })
    updateSellerType(type: string) {
        if (type === 'sellerAuctionHouse') {
            this.SET_SELLER_TYPE('auction_house');

            return;
        }

        if (type === 'sellerGallery') {
            this.SET_SELLER_TYPE('gallery');

            return;
        }

        if (type === 'sellerPainter') {
            this.SET_SELLER_TYPE('painter');

            return;
        }

        this.SET_SELLER_TYPE(type);
    }

    @Action({ rawError: true })
    updateSellerTypeForm(selected: SelectItem) {
        this.RESET_ERROR_BY_FIELD('sellerTypeForm');

        this.SET_SELLER_TYPE_FORM(selected);

        this.SET_CATALOG_IS_REQUIRED(selected.id === 'sellerAuctionHouse' ? true : false);

        this.updateSuggestionsSelectedByType({ type: 'seller', selected: { id: 0, value: '' } });
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'seller', list: [] });

        this.updateSuggestionsSelectedByType({ type: 'catalog', selected: { id: 0, value: '' } });
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'catalog', list: [] });

        this.updateSellerType(selected.id as string);
    }

    @Action({ rawError: true })
    async updateSearchSeller(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ type: 'seller', value: search });

        const filter = `&filters[0][id]=name&filters[0][value]=${search}`;
        let result: any;

        if (this.model.sellerTypeForm.current.id === 'sellerAuctionHouse') {
            result = await getAuctionHouseList(1, this.requestSort, filter, 50);
        }

        if (this.model.sellerTypeForm.current.id === 'sellerGallery') {
            result = await getGalleryList(1, this.requestSort, filter, 50);
        }

        if (this.model.sellerTypeForm.current.id === 'sellerPainter') {
            result = await getPainterList(1, this.requestSort, filter, 50);
        }

        if (!result) {
            return;
        }

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'seller', list: await processListForSelect(result.rows) });
    }

    @Action({ rawError: true })
    updateSelectedSeller(selected: InputSuggestionItem) {
        this.updateSuggestionsSelectedByType({ type: 'seller', selected });

        this.updateSuggestionsSelectedByType({ type: 'catalog', selected: { id: 0, value: '' } });
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'catalog', list: [] });
    }

    @Action({ rawError: true })
    async updateSearchCatalog(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ type: 'catalog', value: search });

        const filterItems = [];

        if (search) {
            filterItems.push({ id: 'name', value: search });
        }

        if (this.model.sellerTypeForm?.current?.id === 'sellerAuctionHouse' && this.model.seller.current?.id) {
            filterItems.push({ id: 'sellerAuctionHouseId', value: this.model.seller.current.id });
        }

        const result = await getCatalogAuctionList(1, this.requestSort, generateFilter(filterItems), 50);
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'catalog', list: await processListForSelect(result.rows) });
    }

    @Action({ rawError: true })
    validate() {
        const requiredFields = Object.keys(this.model).filter((key) => this.model[key]?.required === true);

        this.setValidateState({ index: 'summ', field: this.model.summ });
        this.setValidateRadio({ index: 'operationType', field: this.model.operationType });
        this.setValidateSelect({ index: 'sellerTypeForm', field: this.model.sellerTypeForm });
        this.setValidateSelect({ index: 'seller', field: this.model.seller });

        if (this.model.catalog.required) {
            this.setValidateSelect({ index: 'catalog', field: this.model.catalog });
        }

        const fieldsWithError = requiredFields.filter((field) => this.model[field]?.error?.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareData() {
        const data: {
            operationType: string;
            sum: number;
            comment?: string;
            sellerAuctionHouse?: number;
            sellerGallery?: number;
            sellerPainter?: number;
            auction?: number;
        } = {
            operationType: this.model.operationType.value,
            sum: +this.model.summ.value,
        };

        if (this.model.sellerTypeForm.current.id === 'sellerAuctionHouse') {
            data['sellerAuctionHouse'] = this.model.seller.current.id;
        }

        if (this.model.sellerTypeForm.current.id === 'sellerAuctionHouse' && this.model.catalog.current.id) {
            data['auction'] = this.model.catalog.current.id;
        }

        if (this.model.sellerTypeForm.current.id === 'sellerGallery') {
            data['sellerGallery'] = this.model.seller.current.id;
        }

        if (this.model.sellerTypeForm.current.id === 'sellerPainter') {
            data['sellerPainter'] = this.model.seller.current.id;
        }

        if (this.model.comment.value) {
            data['comment'] = this.model.comment.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async send() {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        this.SET_IS_SEND_BLOCKED(true);
        try {
            const data = await this.prepareData();
            const result = await createTransaction(data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                this.resetAfterSend();
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
        this.SET_IS_SEND_BLOCKED(false);
    }

    @Action({ rawError: true })
    async cancelTransaction(transactionId: number) {
        try {
            const result = await cancelTransaction(transactionId);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: `Транзакция №${transactionId} отменена`, type: 'ok' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    reset() {
        this.SET_SUMM('');
        this.SET_COMMENT('');
        this.SET_OPERATION_TYPE('receipt');
        this.SET_SELLER_TYPE_FORM({ id: 0, value: '' });

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'seller', list: [] });
        this.updateSuggestionsSelectedByType({ type: 'seller', selected: { id: 0, value: '' } });

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'catalog', list: [] });
        this.updateSuggestionsSelectedByType({ type: 'catalog', selected: { id: 0, value: '' } });

        this.SET_CATALOG_IS_REQUIRED(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetAfterSend() {
        this.SET_SUMM('');
        this.SET_COMMENT('');
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.RESET_ERROR_BY_FIELD('summ');
        this.RESET_ERROR_BY_FIELD('sellerTypeForm');
        this.RESET_ERROR_BY_FIELD('seller');
        this.RESET_ERROR_BY_FIELD('catalog');
    }
}

export default getModule(FinanceSellerEntity);
