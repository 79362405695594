import { Vue, Component } from 'vue-property-decorator';
import FinanceModule from '@/admin/store/finance';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import InputBlock from '@/components/input/InputMain.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';
import TextareaBlock from '@/components/form/Textarea.vue';
import RadioBtnGroup from '@/components/form/RadioBtn.vue';
import Loading from '@/components/Loading.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import TableBlock from '@/components/table/Table.vue';
import WarningModal from '@/components/modules/Warning.vue';

@Component({
    components: {
        TabsNav,
        InputBlock,
        InputSuggestions,
        TextareaBlock,
        RadioBtnGroup,
        Loading,
        BtnSubmit,
        BtnDefault,
        TableBlock,
        WarningModal,
    },
})
export default class FinanceMixin extends Vue {
    isWarningShow = false;
    texts = {
        no: 'Нет',
        yes: 'Да',
        title: 'Внимание',
        description: 'Вы действительно хотите отменить транзакцию?',
    };
    transactionToDelete = 0;

    get tabsNav() {
        return FinanceModule.tabsNav;
    }
}
