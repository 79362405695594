
import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import CustomerBlock from './_customer.vue';
import SellerBlock from './_seller.vue';

import FinanceModule from '@/admin/store/finance';
import FinanceCustomerModule from '@/admin/store/finance/customer';
import FinanceSellerModule from '@/admin/store/finance/seller';
import FinanceTableModule from '@/admin/store/finance/table';

@Component({
    components: {
        Layout,
        CustomerBlock,
        SellerBlock,
    },
})
export default class FinanceComponent extends Vue {
    created() {
        FinanceModule.initTabsItems();
        FinanceTableModule.init();
    }

    beforeDestroy() {
        FinanceCustomerModule.reset();
        FinanceSellerModule.reset();
    }
}
