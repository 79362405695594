
import { Component, Mixins } from 'vue-property-decorator';

import FinanceModule from '@/admin/store/finance';
import FinanceCustomerModule from '@/admin/store/finance/customer';
import FinanceTableModule from '@/admin/store/finance/table';
import { SelectItem } from '@/lib/formFactory/select.interface';

import FinanceMixin from './FinanceMixin';
import Inputmask from 'inputmask';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { numberRegExp } from '@/constants';

@Component
export default class FinanceCustomerComponent extends Mixins(FinanceMixin) {
    isWarningShow = false;
    isSubmitDisabled = false;
    texts = {
        no: 'Нет',
        yes: 'Да',
        title: 'Внимание',
        description: 'Вы действительно хотите отменить транзакцию?',
    };
    transactionToDelete = 0;

    get currentTab() {
        return FinanceModule.tabsNav.finance_customer.id;
    }

    get model() {
        return FinanceCustomerModule.model;
    }

    get orderIsLoading() {
        return FinanceCustomerModule.isLoading;
    }

    get isTableLoading() {
        return FinanceTableModule.isLoading;
    }

    get settings() {
        return FinanceTableModule.pageSettings;
    }

    get paginationAction() {
        return FinanceTableModule.updatePage;
    }

    get paginationItems(): { items: string } {
        return {
            items: FinanceTableModule.pageSettings.pageAmountItems,
        };
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    async selectAmount(items: string) {
        await FinanceTableModule.updateItems(items);
        this.requestTable(this.model.customer.current.id);
    }

    updateOperationType(type: string) {
        FinanceCustomerModule.updateOperationType(type);
    }

    updateSumm(summ: string) {
        FinanceCustomerModule.updateSumm(formatRemoveSpaces(summ));
    }

    updateSearchCustomer(search: string) {
        FinanceCustomerModule.updateSearchCustomer(search);
    }

    requestTable(customerId: number) {
        if (!customerId) {
            return;
        }

        FinanceTableModule.getTable({ customerId, subjectType: 'user' });
    }

    updateSelectedCustomer(selected: SelectItem) {
        FinanceCustomerModule.updateSelectedCustomer(selected);
        FinanceCustomerModule.updateSuggestionsSelectedByType({ type: 'order', selected: { id: 0, value: '' } });

        if (selected.id) {
            this.requestTable(+selected.id);

            return;
        }

        FinanceTableModule.RESET_TABLE();
    }

    updateComment(comment: string) {
        FinanceCustomerModule.updateComment(comment);
    }

    updateSearchOrder(search: string) {
        FinanceCustomerModule.updateSearchOrder(formatNumberSpaced(search));
    }

    async updateSelectedOrder(selected: SelectItem) {
        await FinanceCustomerModule.updateSelectedOrder(selected);
        this.requestTable(this.model.customer.current.id);
    }

    async tableAction(params: any) {
        if (params.action === 'sort') {
            await FinanceTableModule.updateSortValue(params);
            this.requestTable(this.model.customer.current.id);
        }

        if (params.action === 'delete') {
            this.transactionToDelete = params.id;
            this.isWarningShow = true;
        }
    }

    async removeTransaction() {
        await FinanceCustomerModule.cancelTransaction(this.transactionToDelete);

        this.transactionToDelete = 0;
        this.isWarningShow = false;

        this.requestTable(this.model.customer.current.id);
    }

    closeModal() {
        this.isWarningShow = false;
        this.transactionToDelete = 0;
    }

    async submit() {
        this.isSubmitDisabled = true;
        const isValidationError = await FinanceCustomerModule.send();
        this.isSubmitDisabled = false;

        if (isValidationError) {
            return;
        }

        this.requestTable(this.model.customer.current?.id);
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    mounted() {
        this.initMask();
        FinanceTableModule.updateTableType('customer');

        if (!this.model.customer.current?.id) {
            return;
        }

        this.requestTable(this.model.customer.current.id);
    }

    beforeDestroy() {
        FinanceTableModule.resetTable();
    }
}
