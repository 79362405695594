import InputEntity from '@/lib/formFactory/inputEntity';
import RadioEntity from '@/lib/formFactory/radioEntity';
import InputSuggestionsEntity from '@/lib/formFactory/inputSuggestionsEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const operationTypeEntity = new RadioEntity('operationType');
        operationTypeEntity.setTitle('');
        operationTypeEntity.setList([
            { id: 0, name: 'Поступление', value: 'receipt' },
            { id: 1, name: 'Выплата', value: 'pay' },
        ]);
        operationTypeEntity.setRequired(true);
        operationTypeEntity.setValue('receipt');

        const summEnity = new InputEntity('summ');
        summEnity.setTitle('Сумма');
        summEnity.setRequired(true);

        const customerEntity = new InputSuggestionsEntity('customer');
        customerEntity.setTitle('Покупатель');
        customerEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        customerEntity.setRequired(true);

        const sellerTypeFormEntity = new SelectEntity('sellerType');
        sellerTypeFormEntity.setTitle('Тип продавца');
        sellerTypeFormEntity.setList([
            { id: 'sellerAuctionHouse', value: 'Аукционный дом' },
            { id: 'sellerGallery', value: 'Галерея' },
            { id: 'sellerPainter', value: 'Автор' },
        ]);
        sellerTypeFormEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerTypeFormEntity.setRequired(true);

        const sellerEntity = new InputSuggestionsEntity('seller');
        sellerEntity.setPlaceholder('Введите имя продавца');
        sellerEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerEntity.setRequired(true);

        const catalogEntity = new InputSuggestionsEntity('catalog');
        catalogEntity.setPlaceholder('Введите название каталога');
        catalogEntity.setValidation({
            empty: 'Поле не заполнено',
        });

        const commentEnity = new InputEntity('comment');
        commentEnity.setTitle('Комментарий');

        this.model = {
            operationType: operationTypeEntity.entity[operationTypeEntity.entityName],
            summ: summEnity.entity[summEnity.entityName],
            comment: commentEnity.entity[commentEnity.entityName],
            customerDebt: '-',
            costDelivery: '-',
            sellerType: '',
            sellerTypeForm: sellerTypeFormEntity.entity[sellerTypeFormEntity.entityName],
            seller: sellerEntity.entity[sellerEntity.entityName],
            catalog: catalogEntity.entity[catalogEntity.entityName],
        };
    }
}
