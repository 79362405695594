import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class InputsSuggestionsMixin extends Vue {
    @Prop({ required: true }) readonly data!: any;
    isShowHints = false;

    documentClick(e: Event) {
        const el = this.$refs.dropdownSearch as HTMLElement;
        const target = e.target as Node;

        if (this.isShowHints === true) {
            if (el !== target && !el.contains(target)) {
                this.close();
                this.$emit('focusout', this.data.value);
            }
        }
    }
    showHints(e: Event) {
        this.$emit('input', (e.target as HTMLInputElement).value ?? '');

        this.isShowHints = true;
    }
    close() {
        this.$emit('close');
        this.isShowHints = false;
    }

    created() {
        document.body.addEventListener('click', this.documentClick);
    }
    updated() {
        document.body.addEventListener('click', this.documentClick);
    }
    destroyed() {
        document.body.removeEventListener('click', this.documentClick);
    }
}
