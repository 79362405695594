import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';

import store from '@/admin/store';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './customerModel';
import { getUsersList } from '@/admin/api/users';
import { generateSort, getApiErrorMessage } from '@/lib/utils/Utils';
import { getOrderCard, getOrdersList } from '@/admin/api/orders';
import { OrderTableItemInterface } from '@/interfaces/orders.interface';
import { UserTableRowInterface } from '@/interfaces/users.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { cancelTransaction, createTransaction } from '@/admin/api/transaction';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { formatRemoveSpaces } from '@/lib/utils/Utils';

const MODULE_NAME = 'financeCustomer';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FinanceCustomerEntity extends EntityBaseModule {
    model: any;
    selectDefault = { id: 0, value: '' };
    requestSort = '';
    isSendBlocked = false;

    constructor(module: FinanceCustomerEntity) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
        this.requestSort = generateSort({ id: 'name', value: 'ASC' });
    }

    @Mutation
    SET_IS_SEND_BLOCKED(bool: boolean) {
        this.isSendBlocked = bool;
    }

    @Mutation
    SET_OPERATION_TYPE(value: string) {
        this.model.operationType.value = value;
    }

    @Mutation
    SET_SUMM(summ: string) {
        this.model.summ.value = summ;
    }

    @Mutation
    SET_ORDER(order: string) {
        this.model.order.value = order;
    }

    @Mutation
    SET_ORDER_SELECTED(selected: SelectItem) {
        this.model.order.current = selected;
    }

    @Mutation
    SET_SELLER_ID(id: number | null) {
        this.model.sellerId = id;
    }

    @Mutation
    SET_SELLER_NAME(name: string) {
        this.model.sellerName = name;
    }

    @Mutation
    SET_SELLER_TYPE(type: string) {
        this.model.sellerType = type;
    }

    @Mutation
    SET_SELLER_PARAM_NAME(name: string) {
        this.model.sellerParamName = name;
    }

    @Mutation
    SET_COST_LOTS(cost: number | string) {
        this.model.costLots = cost;
    }

    @Mutation
    SET_COST_DELIVERY(cost: number | string) {
        this.model.costDelivery = cost;
    }

    @Mutation
    SET_COMMISSION_RIGHT_TO_FOLLOW(commission: number | string) {
        this.model.commissionRightToFollow = commission;
    }

    @Mutation
    SET_COMMISSION_AUCTION_HOUSE(commission: number | string) {
        this.model.commissionAuctionHouse = commission;
    }

    @Mutation
    SET_COMMISSION_FINANCIAL_SERVICES(commission: number | string) {
        this.model.commissionFinancialServices = commission;
    }

    @Mutation
    SET_COMMISSION_SITE(commission: number | string) {
        this.model.commissionSite = commission;
    }

    @Mutation
    SET_COMMISSION_ACQUIRING(commission: number | string) {
        this.model.commissionAcquiring = commission;
    }

    @Mutation
    SET_CATALOG_IS_REQUIRED(bool: boolean) {
        this.model.catalog.required = bool;
    }

    @Action({ rawError: true })
    updateOperationType(type: string) {
        this.SET_OPERATION_TYPE(type);
    }

    @Action({ rawError: true })
    updateSumm(summ: string) {
        this.RESET_ERROR_BY_FIELD('summ');
        this.SET_SUMM(formatRemoveSpaces(summ));
    }

    @Action({ rawError: true })
    updateOrder(order: string) {
        this.SET_ORDER(order);
    }

    @Action({ rawError: true })
    async updateSearchCustomer(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ type: 'customer', value: search });

        const table = await getUsersList(
            1,
            this.requestSort,
            `&filters[0][id]=fullName&filters[0][value]=${search}`,
            50,
        );

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            type: 'customer',
            list: await this.processCustomersListForSelect(table.rows),
        });
    }

    @Action({ rawError: true })
    updateSelectedCustomer(selected: InputSuggestionItem) {
        this.RESET_ERROR_BY_FIELD('customer');
        this.updateSuggestionsSelectedByType({ type: 'customer', selected });
    }

    @Action({ rawError: true })
    processCustomersListForSelect(list: UserTableRowInterface[]) {
        return list.map((customer) => {
            return {
                id: customer.id,
                value: `${customer.fullName} (ID: ${customer.id})`,
            };
        });
    }

    @Action({ rawError: true })
    async updateSearchOrder(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ type: 'order', value: search });

        let filter = search ? `&filters[0][id]=id&filters[0][value]=${search}` : '';
        filter += this.model.customer.current.id
            ? `&filters[1][id]=customer&filters[1][value]=${this.model.customer.current.id}`
            : '';

        const table = await getOrdersList(1, this.requestSort, filter, 50);
        const list = table.rows.map((row: OrderTableItemInterface) => {
            return {
                id: row.id,
                value: row.id,
            };
        });

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'order', list });
    }

    @Action({ rawError: true })
    async updateSelectedOrder(selected: SelectItem) {
        this.SET_SELLER_ID(null);
        this.SET_SELLER_NAME('');
        this.SET_SELLER_TYPE('');
        this.SET_SELLER_PARAM_NAME('');

        this.SET_ORDER(selected.value);
        this.SET_ORDER_SELECTED(selected);

        if (!selected.id) {
            this.resetOrderInfo();
            this.updateSearchOrder('');

            return;
        }

        this.RESET_ERROR_BY_FIELD('order');

        try {
            this.SET_IS_LOADING(true);

            const order = await getOrderCard(selected.id);
            await this.setOrderInfo(order);

            this.SET_IS_LOADING(false);
        } catch (error) {
            this.SET_IS_LOADING(false);
        }
    }

    @Action({ rawError: true })
    setOrderInfo(data: any) {
        this.resetOrderInfo();

        if (!data) {
            return;
        }

        if (this.model.customer?.id !== data.customer.id) {
            const userName = [data.customer.lastName, data.customer.firstName, data.customer.middleName]
                .filter((name) => name)
                .join(' ');

            this.updateSuggestionsSelectedByType({
                selected: {
                    id: data.customer.id,
                    value: `${userName} (ID: ${data.customer.id})`,
                },
                type: 'customer',
            });
        }

        if (data.sellerAuctionHouse !== null) {
            this.SET_SELLER_TYPE('auction_house');
            this.SET_SELLER_PARAM_NAME('auctionHouseId');
            this.SET_SELLER_ID(data.sellerAuctionHouse.id);
            this.SET_SELLER_NAME(data.sellerAuctionHouse.name);
        }

        if (data.sellerGallery !== null) {
            this.SET_SELLER_TYPE('gallery');
            this.SET_SELLER_PARAM_NAME('galleryId');
            this.SET_SELLER_ID(data.sellerGallery.id);
            this.SET_SELLER_NAME(data.sellerGallery.name);
        }

        if (data.sellerPainter !== null) {
            this.SET_SELLER_TYPE('painter');
            this.SET_SELLER_PARAM_NAME('painterId');
            this.SET_SELLER_ID(data.sellerPainter.id);
            this.SET_SELLER_NAME(data.sellerPainter.name);
        }

        this.SET_COST_LOTS(data.costLots || '-');
        this.SET_COST_DELIVERY(data.costDelivery || '-');
        this.SET_COMMISSION_RIGHT_TO_FOLLOW(data.commissionRightToFollow || '-');
        this.SET_COMMISSION_AUCTION_HOUSE(data.commissionAuctionHouse || '-');
        this.SET_COMMISSION_FINANCIAL_SERVICES(data.commissionFinancialServices || '-');
        this.SET_COMMISSION_SITE(data.commissionSite || '-');
        this.SET_COMMISSION_ACQUIRING(data.commissionAcquiring || '-');
    }

    @Action({ rawError: true })
    validate() {
        const requiredFields = Object.keys(this.model).filter((key) => this.model[key]?.required === true);

        this.setValidateState({ index: 'summ', field: this.model.summ });
        this.setValidateSelect({ index: 'customer', field: this.model.customer });
        this.setValidateSelect({ index: 'order', field: this.model.order });
        this.setValidateRadio({ index: 'operationType', field: this.model.operationType });

        const fieldsWithError = requiredFields.filter((field) => this.model[field]?.error?.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareData() {
        const data: {
            operationType: string;
            sum: number;
            customer: number;
            order: number;
            comment?: string;
        } = {
            operationType: this.model.operationType.value,
            sum: +this.model.summ.value,
            order: this.model.order.current.id,
            customer: this.model.customer.current.id,
        };

        if (this.model.comment.value) {
            data['comment'] = this.model.comment.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async send() {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return isError;
        }

        this.SET_IS_SEND_BLOCKED(true);
        try {
            const data = await this.prepareData();
            const result = await createTransaction(data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                this.resetAfterSend();
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
        this.SET_IS_SEND_BLOCKED(false);
    }

    @Action({ rawError: true })
    async cancelTransaction(transactionId: number) {
        try {
            const result = await cancelTransaction(transactionId);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: `Транзакция №${transactionId} отменена`, type: 'ok' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    resetAfterSend() {
        this.SET_SUMM('');
        this.SET_COMMENT('');
        this.updateSuggestionsSelectedByType({ type: 'order', selected: { id: 0, value: '' } });
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'order', list: [] });

        this.resetOrderInfo();
    }

    @Action({ rawError: true })
    reset() {
        this.SET_OPERATION_TYPE('receipt');
        this.SET_SUMM('');
        this.SET_COMMENT('');

        this.updateSuggestionsSelectedByType({ type: 'customer', selected: { id: 0, value: '' } });
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'customer', list: [] });

        this.updateSuggestionsSelectedByType({ type: 'order', selected: { id: 0, value: '' } });
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'order', list: [] });

        this.resetOrderInfo();
        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetOrderInfo() {
        this.SET_SELLER_ID(null);
        this.SET_SELLER_NAME('');
        this.SET_SELLER_TYPE('');
        this.SET_SELLER_PARAM_NAME('');
        this.SET_COST_LOTS('-');
        this.SET_COST_DELIVERY('-');
        this.SET_COMMISSION_RIGHT_TO_FOLLOW('-');
        this.SET_COMMISSION_AUCTION_HOUSE('-');
        this.SET_COMMISSION_FINANCIAL_SERVICES('-');
        this.SET_COMMISSION_SITE('-');
        this.SET_COMMISSION_ACQUIRING('-');

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.RESET_ERROR_BY_FIELD('order');
        this.RESET_ERROR_BY_FIELD('customer');
    }
}

export default getModule(FinanceCustomerEntity);
