
import { Component, Mixins } from 'vue-property-decorator';
import SelectCustom from '@/components/select/SelectCustom.vue';

import FinanceModule from '@/admin/store/finance';
import FinanceSellerModule from '@/admin/store/finance/seller';
import FinanceTableModule from '@/admin/store/finance/table';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';

import FinanceMixin from './FinanceMixin';
import { formatNumberSpaced } from '@/lib/utils/Utils';
import Inputmask from 'inputmask';
import { numberRegExp } from '@/constants';


@Component({
    components: {
        SelectCustom,
    },
})
export default class FinanceSellerComponent extends Mixins(FinanceMixin) {
    isSubmitDisabled = false;

    get currentTab() {
        return FinanceModule.tabsNav.finance_seller.id;
    }

    get model() {
        return FinanceSellerModule.model;
    }

    get isOrderShow() {
        if (
            this.model.sellerType === 'auction_house' &&
            this.model.seller?.current?.id &&
            this.model.catalog?.current?.id
        ) {
            return true;
        }

        if (this.model.sellerType !== 'auction_house' && this.model.seller?.current?.id) {
            return true;
        }

        return false;
    }

    get orderIsLoading() {
        return FinanceSellerModule.isLoading;
    }

    get isTableLoading() {
        return FinanceTableModule.isLoading;
    }

    get settings() {
        return FinanceTableModule.pageSettings;
    }

    get paginationAction() {
        return FinanceTableModule.updatePage;
    }

    get paginationItems(): { items: string } {
        return {
            items: FinanceTableModule.pageSettings.pageAmountItems,
        };
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    async selectAmount(items: string) {
        await FinanceTableModule.updateItems(items);
        this.requestTable(this.model.seller.current.value, this.model.catalog.current.value);
    }

    updateOperationType(type: string) {
        FinanceSellerModule.updateOperationType(type);
    }

    updateSumm(summ: string) {
        FinanceSellerModule.updateSumm(formatNumberSpaced(summ));
    }

    requestTable(sellerName: string, catalogName?: string) {
        if (!sellerName) {
            return;
        }

        let params: {
            sellerName: string;
            subjectType: string;
            catalogName?: string;
        } = {
            sellerName,
            subjectType: this.model.sellerType,
        };

        if (catalogName) {
            params = { ...params, catalogName };
        }

        FinanceTableModule.getTable(params);
    }

    updateComment(comment: string) {
        FinanceSellerModule.updateComment(comment);
    }

    updateSellerTypeForm(selected: SelectItem) {
        FinanceSellerModule.updateSellerTypeForm(selected);
    }

    updateSearchSeller(search: string) {
        FinanceSellerModule.updateSearchSeller(search);
    }

    updateSelectedSeller(selected: InputSuggestionItem) {
        FinanceSellerModule.updateSelectedSeller(selected);
        this.requestTable(selected.value);
    }

    updateSearchCatalog(search: string) {
        FinanceSellerModule.updateSearchCatalog(search);
    }

    updateSelectedCatalog(selected: InputSuggestionItem) {
        FinanceSellerModule.updateSuggestionsSelectedByType({ type: 'catalog', selected });
        this.requestTable(this.model.seller.current.value, selected.value);
    }

    async tableAction(params: any) {
        if (params.action === 'sort') {
            await FinanceTableModule.updateSortValue(params);
            this.requestTable(this.model.seller.current.value, this.model.catalog.current.value);
        }

        if (params.action === 'delete') {
            this.transactionToDelete = params.id;
            this.isWarningShow = true;
        }
    }

    resetTransactions() {
        this.requestTable(this.model.seller.current.value, this.model.catalog.current.value);
    }

    async removeTransaction() {
        await FinanceSellerModule.cancelTransaction(this.transactionToDelete);

        this.transactionToDelete = 0;
        this.isWarningShow = false;

        this.requestTable(this.model.seller.current.value, this.model.catalog.current.value);
    }

    closeModal() {
        this.isWarningShow = false;
        this.transactionToDelete = 0;
    }

    async submit() {
        this.isSubmitDisabled = true;
        await FinanceSellerModule.send();
        this.isSubmitDisabled = false;

        this.requestTable(this.model.seller.current.value, this.model.catalog.current.value);
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    mounted() {
        this.initMask();
        
        FinanceTableModule.updateTableType('seller');

        if (!this.model.seller.current?.id) {
            return;
        }

        this.requestTable(this.model.seller.current.value, this.model.catalog.current.value);
    }

    beforeDestroy() {
        FinanceTableModule.resetTable();
    }
}
