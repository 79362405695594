export const tabsNav = {
    finance_customer: {
        id: 'finance_customer',
        name: 'Расчеты с покупателями',
        template: 'finance_customer',
        pathName: 'finance_customer',
        icon: '',
        iconSize: '16',
    },
    finance_seller: {
        id: 'finance_seller',
        name: 'Расчёты с продавцами',
        template: 'finance_seller',
        pathName: 'finance_seller',
        icon: '',
        iconSize: '16',
    },
};
